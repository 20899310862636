@import "../variables";

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 14;
  background-color: rgba(0, 0, 0, 0.8);
  display: none;

  @media ($tablet) {
    z-index: 20;
  }

  &--show {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}