@import "../variables";

.rate {
  background-color: #f0f4f8;
  padding: 50px 0 40px;

  @media ($tablet) {
    padding: 70px 0 10px;
  }

  @media ($desktop) {
    .title-h2 {
      font-size: 50px;
      line-height: 70px;
    }
  }

  &--white {
    background-color: #fff;
  }
}

.rate__list {
  margin: 60px 0 0;
  padding: 0;
  list-style-type: none;

  @media ($tablet) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 30px;
  }

  @media ($desktop) {
    padding: 0;

    &--fourth {
      .rate__item {
        width: 270px;
      }
    }

    &--third {
      .rate__item {
        width: 370px;
      }
    }
  }
}

.rate__item {
  margin-bottom: 90px;
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }

  @media ($tablet) {
    width: 264px;

    &:last-child {
      margin-bottom: 90px;
    }
  }

  @media ($desktop) {
    box-sizing: border-box;
    padding: 30px;
    border-left: 1px solid #f2f2f2;
    transition: background-color .2s;

    &:hover {
      background-color: rgba(12, 158, 251, 0.1);
    }
  }

  .btn-default {
    width: 100%;
    height: 60px;
  }

  &--profit {
    &::before {
      content: 'Это выгодно';
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      align-items: center;
      height: 30px;
      padding: 0 10px 0 30px;
      font-family: $fontSemiBold;
      color: #fff;
      font-size: 12px;
      line-height: 8px;
      border-radius: 15px;
      box-shadow: 0 5px 15px 0 rgba(15, 116, 15, 0.3);
      background: #2eb22f url('../img/svg/icon-star.svg') 10px 50% no-repeat;

      @media ($desktop) {
        top: 37px;
      }
    }
  }
}

.rate__title {
  font-family: $fontBold;
  font-size: 20px;
  line-height: 27px;
  margin: 0 0 20px;

  @media ($tablet) {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 15px;
  }
}

.rate__sum {
  font-family: $fontBold;
  font-size: 22px;
  margin: 0 0 20px;
  color: #0c9efb;

  @media ($tablet) {
    font-size: 26px;
  }
}

.rate__text {
  font-size: 14px;
  line-height: 21px;
  margin: 0;
  padding-bottom: 30px;
  border-bottom: 1px solid #fff;

  @media ($tablet) {
    font-size: 16px;
    line-height: 26px;
  }
}

.rate__desc {
  margin: 30px 0 20px;
  padding: 0;
  list-style-type: none;

  @media ($tablet) {
    margin-bottom: 60px;
  }
}

.rate__desc-item {
  position: relative;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 10px;
  padding-left: 15px;

  @media ($tablet) {
    margin-bottom: 15px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 8px;
    left: 0;
    width: 4px;
    height: 4px;
    background-color: #0c9efb;
    border-radius: 100%;
  }

  &--highlight {
    font-family: $fontBold;
  }
}