@import "../variables";

.gallery {
  @media ($tablet) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.gallery__link {
  display: inline-block;
  margin-bottom: 30px;

  @media ($tablet) {
    &--2 {
      flex-basis: 48%;
    }

    &--3 {
      flex-basis: 30%;
    }
  }

  img {
    width: 100%;
  }
}