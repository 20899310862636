@import "../variables";

.breadcrumbs {
  margin: 0 0 20px;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
}

.breadcrumbs__item {
  &:not(:first-child) {
    &::before {
      content: '/';
      display: inline-block;
      margin: 0 3px 0 5px;
      font-size: 14px;
      color: #ababad;
    }
  }

  &:last-child {
    pointer-events: none;
  }
}

.breadcrumbs__link {
  font-size: 14px;
  color: #ababad;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}