@import "../variables";

.title {
  font-weight: 400;
  margin: 0;
}

.title-default {
  font-family: $fontSemiBold;
  font-size: 22px;
  line-height: 33px;

  @media ($tablet) {
    font-size: 32px;
    line-height: 44px;
  }

  @media ($desktop) {
    font-size: 48px;
    line-height: 58px;
  }
}

.title-h1 {
  font-family: $fontBold;
  font-size: 32px;
  line-height: 46px;
  margin-bottom: 30px;
}

.title-h2 {
  font-family: $fontBold;
  font-size: 28px;
  line-height: 39px;
  margin-bottom: 30px;

  &--reverse {
    color: #fff;
  }
}

.title-h3 {
  font-family: $fontBold;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 30px;
}

.title-h4 {
  font-family: $fontBold;
  font-size: 22px;
  line-height: 30px;
  margin-bottom: 30px;
}

.title-h5 {
  font-family: $fontBold;
  font-size: 20px;
  line-height: 27px;
  margin-bottom: 30px;
}

.title-h6 {
  font-family: $fontSemiBold;
  font-size: 16px;
  line-height: 23px;
  margin-bottom: 30px;
}