@import "../variables";

.input {
  padding: 0;
  margin: 0;
  background: transparent;
  box-sizing: border-box;
  border: none;
  font-family: $fontRegular;
}

.input-checkbox {
  display: none;

  &:checked + .label-check::before {
    background: #0c9efb url('../img/svg/icon-check.svg') 50% 50% no-repeat;
  }
}