@import "../variables";

.request {
  margin: 0 -15px;
  background-color: #fff;
  color: #000;
  padding: 15px;
  border-radius: 6px;

  @media ($tablet) {
    margin: 0 auto;
    width: 515px;
    box-sizing: border-box;
  }
}

.request__wrapper {
  @media ($tablet) {
    border: 2px solid rgba(0,0,0,.04);
    border-radius: 5.9px;
    padding: 30px 55px 25px;
  }
}

.request__item {
  position: relative;

  &:not(:first-child) {
    margin-top: 20px;

    @media ($tablet) {
      margin-top: 25px;
    }
  }

  &--agree {
    padding-left: 9px;

    @media ($tablet) {
      padding-left: 0;
    }
  }
}

.request__title {
  font-family: $fontBold;
  font-size: 16px;
  line-height: 23px;
  margin: 0 0 20px;

  @media ($tablet) {
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 30px;
  }
}

.request__label {
  opacity: .3;

  @media ($tablet) {
    font-size: 16px;
  }
}

.request__input {
  width: 100%;
  border-bottom: 1px solid #e6e6e6;
  height: 50px;
  margin-top: 5px;
  font-size: 16px;
  padding: 0 15px 0 42px;
  font-family: $fontBold;

  @media ($tablet) {
    height: 60px;
    font-size: 18px;
  }
}

.request__item-name {
  width: 20px;
  height: 19px;
  position: absolute;
  left: 0;
  top: 50px;

  @media ($tablet) {
    top: 56px;
  }
}

.request__item-phone {
  width: 20px;
  height: 20px;
  position: absolute;
  left: 0;
  top: 50px;

  @media ($tablet) {
    top: 56px;
  }
}

.request__button {
  width: 100%;
  height: 67px;
  border-radius: 38.3px;
  border-bottom: 3px solid #178fdb;
  font-size: 16px;
  box-shadow: 0 10px 30px -5px rgba(28, 150, 227, 0.5);
}

.request__check {
  font-size: 12px;
  line-height: 32px;

  @media ($tablet) {
    font-size: 14px;
    justify-content: center;
  }
}

.request__link {
  margin-left: 3px;
  color: #0c9efb;
}