@import "../variables";

.news {
  padding-top: 30px;
}

.news__item {
  &:not(:first-child) {
    margin-top: 30px;
  }

  @media ($tablet) {
    display: flex;
    justify-content: space-between;

    &--right {
      flex-direction: row-reverse;
    }
  }

  .btn-default {
    @media ($desktop) {
      height: 60px;
      width: 200px;
    }
  }
}

.news__img {
  margin-bottom: 16px;

  img {
    display: block;
    width: 100%;
  }

  @media ($tablet) {
    flex-basis: 48%;
  }

  @media ($desktop) {
    flex-basis: 49%;
  }
}

.news__desc {
  @media ($tablet) {
    flex-basis: 48%;
  }

  @media ($desktop) {
    flex-basis: 49%;
  }
}

.news__name {
  margin: 0 0 15px;
  font-family: $fontBold;
  font-size: 24px;
  line-height: 32px;

  @media ($tablet) {
    margin-bottom: 20px;
  }

  @media ($desktop) {
    font-size: 48px;
    line-height: 58px;
    margin-bottom: 30px;
  }
}

.news__text {
  font-size: 14px;
  line-height: 21px;
  margin: 0 0 15px;

  @media ($tablet) {
    margin-bottom: 20px;
  }

  @media ($desktop) {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 30px;
  }
}

.news__all {
  text-align: center;
  margin-top: 40px;
}