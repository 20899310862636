@import "../variables";

.top {
  padding: 112px 0 50px;
  background: #4e6379 url('../img/bg-top.jpg') 50% 0 no-repeat;
  background-size: cover;
  position: relative;

  @media ($tablet) {
    padding: 251px 0 70px;
    background-image: url('../img/bg-top-tablet.jpg');
  }

  @media ($desktop) {
    padding: 173px 0 35px;
    background-image: url('../img/bg-promo-desktop.jpg');
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #4e6379;
    opacity: .55;
    z-index: 1;
  }
}

.top__wrapper {
  position: relative;
  z-index: 2;

  @media ($tablet) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @media ($desktop) {
    align-items: flex-start;
  }

  .title-default {
    font-family: $fontBold;
    font-size: 24px;
    line-height: 34px;
    color: #fff;
    margin-bottom: 30px;

    @media ($tablet) {
      font-size: 28px;
      line-height: 40px;
      margin-bottom: 36px;
    }

    @media ($desktop) {
      font-size: 50px;
      line-height: 70px;
      width: 490px;
      margin-bottom: 30px;
    }
  }
}

.top__desc {
  @media ($tablet) {
    flex-basis: 48%;
    position: relative;
    display: flex;
    flex-direction: column;

    &::after {
      content: url('../img/question-arrow.png');
      margin-left: auto;
      margin-top: 30px;
    }
  }

  @media ($desktop) {
    flex-basis: 45%;
    padding-top: 40px;

    &::after {
      position: absolute;
      bottom: -110px;
      right: -170px;
      margin-top: 0;
    }
  }
}

.top__text {
  font-size: 12px;
  line-height: 20px;
  color: #fff;
  margin: 0 0 30px;

  @media ($tablet) {
    font-size: 14px;
    line-height: 22px;
    margin: 0;
  }

  @media ($desktop) {
    font-size: 16px;
    line-height: 24px;
  }
}

.top__form {
  @media ($tablet) {
    flex-basis: 48%;

    .request {
      width: 100%;
      padding: 20px;
    }

    .request__wrapper {
      border: none;
      padding: 0;
    }

    .request__title {
      font-size: 18px;
      line-height: 24px;
    }

    .request__item {
      &:not(:first-child) {
        margin-top: 20px;
      }
    }

    .request__check {
      font-size: 12px;
      justify-content: flex-start;
    }
  }

  @media ($desktop) {
    flex-basis: 37%;

    .request {
      padding: 30px;
    }

    .request__title {
      font-size: 22px;
      line-height: 32px;
    }

    .request__item {
      &:not(:first-child) {
        margin-top: 25px;
      }
    }

    .request__check {
      justify-content: center;
    }
  }
}