@import "../variables";

.promo {
  &--image {
    padding: 103px 0 50px;
    background: #4e6379 url('../img/bg-promo-mobile.jpg') 50% 0 no-repeat;
    background-size: cover;
    position: relative;

    @media ($tablet) {
      padding: 253px 0 45px;
      background-image: url('../img/bg-promo-tablet.jpg');
    }

    @media ($desktop) {
      padding: 200px 0 25px;
      background-image: url('../img/bg-promo-desktop.jpg');
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: #4e6379;
      opacity: .55;
      z-index: 1;
    }
  }
}

.promo__wrapper {
  position: relative;
  z-index: 2;
}

.promo__pagination {
  width: auto;
  bottom: auto;
  top: 0;
  color: #fff;
  display: flex;
  align-items: flex-start;
  font-size: 12px;
  font-family: $fontBold;

  @media ($tablet) {
    font-size: 18px;
    top: 8px;
  }

  .swiper-pagination-current {
    font-size: 32px;
    opacity: 1;
    line-height: 24px;

    @media ($tablet) {
      font-size: 38px;
      line-height: 32px;
    }

    &::before {
      content: '0';
    }
  }

  .swiper-pagination-total {
    opacity: .4;

    &::before {
      content: '0';
    }
  }
}

.promo__controls {
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-direction: row-reverse;
  height: 55px;
  margin-top: 40px;

  @media ($tablet) {
    margin-top: 80px;
  }

  @media ($desktop) {
    margin-top: 70px;
  }
}

.promo__next,
.promo__prev {
  box-sizing: border-box;
  position: static;
  background-image: none;
  width: 55px;
  height: 55px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(255,255,255,.3);
  margin: 0;
  fill: #fff;
  transition: background-color .2s;

  &:hover {
    background-color: #fff;
    fill: #000;
  }
}

.promo__prev {
  transform: rotate(-180deg);
  margin-right: 20px;
}

.promo__next-svg {
  width: 12px;
  height: 12px;
}

.promo__scroll {
  display: none;

  @media ($tablet) {
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(90deg);
    position: absolute;
    left: -50px;
    bottom: 50px;
  }

  @media ($desktop) {
    bottom: 80px;
  }
}

.promo__scroll-text {
  font-size: 14px;
  line-height: 7px;
  color: #fff;
  opacity: .4;
}

.promo__scroll-svg {
  width: 18px;
  height: 18px;
  transform: rotate(-90deg);
  margin-right: 10px;

  @media ($desktop) {
    margin-right: 15px;
  }
}

.promo__social {
  display: none;

  @media ($tablet) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style-type: none;
    position: absolute;
    right: 0;
    top: 75px;
    z-index: 10;
  }

  @media ($desktop) {
    top: 115px;
  }
}

.promo__social-item {
  margin-bottom: 20px;

  @media ($desktop) {
    margin-bottom: 30px;
  }
}

.promo__social-vk {
  width: 14px;
  height: 8px;
  fill: #fff;
}

.promo__social-fb {
  width: 7px;
  height: 14px;
  fill: #fff;
}

.promo__social-tw {
  width: 14px;
  height: 12px;
  fill: #fff;
}