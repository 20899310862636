@import "../variables";

.label {
  display: inline-block;
  vertical-align: middle;
}

.label-default {
  font-size: 14px;
  line-height: 32px;
}

.label-check {
  display: flex;
  align-items: center;

  &::before {
    content: '';
    width: 14px;
    height: 14px;
    border-radius: 2px;
    border: 1px solid #0c9efb;
    margin-right: 10px;
    box-sizing: border-box;
  }
}