@import "../variables";

.articles {
  background-color: #f0f4f8;
  padding: 30px 0 10px;

  @media ($tablet) {
    padding: 70px 0 1px;

    .title-h2 {
      width: 324px;
      font-size: 36px;
      line-height: 48px;
      margin-bottom: 65px;
    }
  }

  @media ($desktop) {
    .title-h2 {
      width: 770px;
      font-size: 50px;
      line-height: 70px;
      margin-bottom: 70px;
    }
  }

  &--white {
    background-color: #fff;
  }
}

.articles__list {
  margin: 0;
  padding: 0;
  list-style-type: none;

  @media ($tablet) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &--wide {
      .articles__item {
        flex-basis: 100%;
      }
    }
  }

  @media ($desktop) {
    &--wide {
      .articles__item {
        flex-basis: 48%;
      }
    }
  }

  &--fourth {
    @media ($desktop) {
      .articles__item {
        flex-basis: 23%;
      }
    }
  }

  &--third {
    @media ($desktop) {
      .articles__item {
        flex-basis: 31.7%;
      }
    }
  }
}

.articles__item {
  margin-bottom: 30px;

  @media ($tablet) {
    flex-basis: 48%;
    margin-bottom: 70px;
  }

  .btn-default {
    height: 60px;
  }
}

.articles__img {
  width: 100%;
  margin-bottom: 20px;

  @media ($tablet) {
    margin-bottom: 30px;
  }
}

.articles__title {
  font-family: $fontSemiBold;
  font-size: 16px;
  line-height: 23px;
  margin: 0 0 20px;

  @media ($tablet) {
    font-size: 18px;
  }
}

.articles__text {
  font-size: 12px;
  line-height: 18px;
  margin: 0 0 20px;

  @media ($tablet) {
    font-size: 14px;
    line-height: 22px;
  }
}