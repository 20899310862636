@import "variables";

body {
  font-family: $fontRegular;
  font-size: 14px;
  background-color: #fff;
  min-width: 320px;
  color: #000;

  @media ($tablet) {
    font-size: 16px;
  }

  @media ($desktop) {
    font-size: 18px;
  }
}

.container {
  min-width: 320px;
  padding: 0 15px;
  margin: 0 auto;
  box-sizing: border-box;

  @media ($tablet) {
    padding: 0 45px;
  }

  @media ($desktop) {
    padding: 0;
    width: 1170px;
  }
}

a {
  color: #000;

  &:hover {
    text-decoration: none;
  }
}