@import "../variables";

.btn {
  display: inline-block;
  background-color: transparent;
  padding: 0;
  margin: 0;
  border: none;
}

.btn-default {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 168px;
  height: 45px;
  background-color: #0c9efb;
  border-radius: 30px;
  text-decoration: none;
  color: #fff;
  font-family: $fontBold;
  font-size: 14px;
  transition: background-color .2s;

  &:hover {
    background-color: #48b6fd;
  }

  &:active {
    background-color: #0a7dc8;
  }
}