@import "../variables";

.header {
  padding: 15px 0;
  box-sizing: border-box;
  border-bottom: 2px solid #f2f2f2;
  position: relative;
  z-index: 15;

  @media ($tablet) {
    border-bottom: none;

    .nav__item {
      &:hover {
        .nav__link,
        .nav__drop {
          background-color: #ccc;
        }
      }
    }
  }

  .header__logo-white {
    display: none;
  }

  &--image {
    margin-bottom: -86px;
    border-bottom: 2px solid rgba(255,255,255,.2);

    @media ($tablet) {
      margin-bottom: -208px;
      border-bottom: none;

      .nav {
        border-bottom: 2px solid rgba(255,255,255,.2);
      }

      .nav__item {
        &:hover {
          .nav__link,
          .nav__drop {
            background-color: #fff;
          }
        }
      }

      .nav__link {
        color: #fff;
      }
    }

    @media ($desktop) {
      margin-bottom: -157px;

      .nav {
        border-bottom: none;
      }

      .header__wrapper {
        border-bottom: 2px solid rgba(255,255,255,.2);
      }
    }

    .header__logo-white {
      display: block;
    }

    .header__logo-blue {
      display: none;
    }

    .header__button-svg {
      fill: #fff;
    }

    .header__logo-text,
    .header__phone {
      color: #fff;
    }
  }
}

.header__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ($tablet) {
    flex-wrap: wrap;
  }

  @media ($desktop) {
    justify-content: flex-start;
    padding-bottom: 12px;
    border-bottom: 2px solid rgba(0,0,0,.2);
  }
}

.header__link {
  text-decoration: none;
}

.header__logo {
  @media ($desktop) {
    order: 1;
  }
}

.header__logo-white,
.header__logo-blue {
  @media ($tablet) {
    width: 63px;
    height: 70px;
  }
}

.header__logo-text {
  display: none;

  @media ($tablet) {
    display: block;
    margin-top: 7px;
    font-size: 11px;
    line-height: 16px;
  }
}

.header__button {
  @media ($tablet) {
    display: none;
  }
}

.header__button-svg {
  width: 20px;
  height: 14px;
  fill: #000;
}

.header__call {
  display: none;

  @media ($tablet) {
    display: block;
    text-align: right;
  }

  @media ($desktop) {
    order: 3;
    display: flex;
    align-items: center;
    margin-left: auto;
  }
}

.header__call-wrapper {
  @media ($tablet) {
    display: flex;
    flex-direction: column;
  }
}

.header__phone {
  font-family: $fontBold;
  font-size: 18px;
  text-decoration: none;
  padding-right: 25px;
}

.header__callback {
  width: 206px;
  margin-top: 10px;

  @media ($desktop) {
    margin-top: 0;
  }
}