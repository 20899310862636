@import "../variables";

.nav {
  position: fixed;
  top: 0;
  left: -100%;
  bottom: 0;
  background-color: #fff;
  width: 95%;
  padding: 15px 15px 50px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  transition: left .2s;

  @media ($tablet) {
    position: static;
    background: none;
    width: 100%;
    padding: 0;
    border-bottom: 2px solid rgba(0,0,0,.2);
  }

  @media ($desktop) {
    order: 2;
    width: auto;
    margin-left: 28px;
    border-bottom: none;
    padding: 0;
  }

  &--show {
    left: 0;
  }
}

.nav__header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ($tablet) {
    display: none;
  }
}

.nav__header-link {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.nav__header-text {
  font-size: 10px;
  line-height: 15px;
  margin-left: 14px;
}

.nav__close-svg {
  width: 16px;
  height: 16px;
}

.nav__list {
  margin: 50px 0;
  padding: 0;
  list-style-type: none;

  @media ($tablet) {
    display: flex;
    justify-content: space-between;
    margin: 25px -15px 0;
  }

  @media ($desktop) {
    margin: 0 0 0 -20px;
  }
}

.nav__item {
  position: relative;

  &:not(:first-child) {
    margin-top: 30px;

    @media ($tablet) {
      margin: 0;
    }
  }

  &:hover {
    .nav__link {
      text-decoration: none;
      background-color: #fff;
      color: #000;
    }

    @media ($tablet) {
      .nav__drop {
        display: block;
      }

      .nav__drop-arrow {
        transform: rotate(-90deg);
      }
    }
  }
}

.nav__link {
  font-family: $fontBold;
  font-size: 14px;
  line-height: 10px;
  text-transform: uppercase;
  text-decoration: none;
  color: #000;
  display: inline-block;

  &:hover {
    text-decoration: underline;
  }

  @media ($tablet) {
    padding: 15px;
    margin-bottom: -4px;

    &:hover {
      text-decoration: none;
      background-color: #fff;
      color: #000;
    }
  }

  &--drop {
    display: flex;
    align-items: center;
  }
}

.nav__call {
  margin-top: auto;
  display: flex;
  flex-direction: column;

  @media ($tablet) {
    display: none;
  }
}

.nav__phone {
  font-family: $fontBold;
  font-size: 28px;
  line-height: 39px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.nav__callback {
  width: 190px;
  height: 50px;
  margin-top: 20px;
}

.nav__drop {
  display: none;
  margin: 15px;
  padding: 0;
  list-style-type: none;

  @media ($tablet) {
    position: absolute;
    top: 30px;
    left: 0;
    background-color: #fff;
    margin: 0;
    padding: 15px;
  }

  &--open {
    display: block;
  }
}

.nav__drop-item {
  margin-bottom: 5px;
}

.nav__drop-link {
  font-size: 12px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.nav__drop-arrow {
  width: 12px;
  height: 12px;
  margin-left: 10px;
  transform: rotate(90deg);

  &--open {
    transform: rotate(-90deg);
  }
}