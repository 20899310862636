@import "../variables";

.benefits {
  padding: 50px 0 1px;

  @media ($tablet) {
    padding: 70px 0 10px;
  }
}

.benefits__header {
  margin-bottom: 60px;

  @media ($tablet) {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  .btn-default {
    width: 230px;
    height: 60px;

    @media ($tablet) {
      width: 270px;
      margin-bottom: 5px;
    }
  }

  .title-h2 {
    @media ($tablet) {
      margin-bottom: 0;
      width: 315px;
    }

    @media ($desktop) {
      width: 520px;
      font-size: 50px;
      line-height: 70px;
    }
  }
}

.benefits__list {
  margin: 0;
  padding: 0;
  list-style-type: none;

  @media ($tablet) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &--fourth {
    @media ($desktop) {
      .benefits__item {
        flex-basis: 23%;
      }
    }
  }
}

.benefits__item {
  margin-bottom: 90px;

  @media ($tablet) {
    flex-basis: 47%;
    box-sizing: border-box;
    padding: 30px;
    border-left: 1px solid #f2f2f2;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 60px;
    transition: background-color .2s;

    &:hover {
      background-color: rgba(12, 158, 251, 0.1);
    }
  }
}

.benefits__icon {
  margin-bottom: 20px;
}

.benefits__title {
  font-family: $fontSemiBold;
  font-size: 16px;
  margin: 0 0 20px;

  @media ($tablet) {
    font-size: 18px;
  }
}

.benefits__text {
  font-size: 12px;
  line-height: 18px;
  margin: 0 0 30px;

  @media ($tablet) {
    font-size: 14px;
    line-height: 22px;
  }
}

.benefits__link {
  display: flex;
  align-items: center;
  text-decoration: none;

  @media ($tablet) {
    margin-top: auto;
    font-size: 14px;
  }

  &:hover {
    text-decoration: underline;

    .benefits__svg {
      margin-left: 12px;
    }
  }
}

.benefits__svg {
  width: 12px;
  height: 12px;
  margin-left: 9px;
  transition: margin-left .2s;
}