@import "../variables";

.reviews {
  padding: 50px 0;

  @media ($tablet) {
    padding: 70px 0;

    .container {
      position: relative;
    }

    .title-h2 {
      width: 324px;
      font-size: 36px;
      line-height: 48px;
    }
  }

  @media ($desktop) {
    padding: 70px 0 100px;

    .title-h2 {
      width: 390px;
      font-size: 50px;
      line-height: 70px;
      margin-bottom: 70px;
    }
  }
}

.reviews__wrapper {
  @media ($tablet) {
    display: flex;
  }

  @media ($desktop) {
    align-items: flex-start;
  }
}

.reviews__add {
  margin-bottom: 50px;

  @media ($tablet) {
    width: 206px;
    margin-bottom: 0;
    flex-shrink: 0;
    margin-right: 30px;
  }

  @media ($desktop) {
    width: 370px;
  }

  .btn-default {
    width: 100%;
    height: 60px;

    @media ($desktop) {
      width: 270px;
    }
  }
}

.reviews__desc {
  font-size: 12px;
  line-height: 18px;
  margin: 0 0 20px;

  @media ($desktop) {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 40px;
  }
}

.reviews__control {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
  margin-bottom: 30px;

  @media ($tablet) {
    position: absolute;
    top: 30px;
    right: 45px;
    margin-bottom: 0;
  }

  @media ($desktop) {
    right: 0;
    top: 70px;
  }
}

.reviews__slider {
  @media ($tablet) {
    margin-right: -45px;
  }

  @media ($desktop) {
    margin-right: 0;
  }
}

.reviews__next,
.reviews__prev {
  box-sizing: border-box;
  position: static;
  background-image: none;
  width: 55px;
  height: 55px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #0c9efb;
  background-color: #fff;
  margin: 0;
  transition: background-color .2s;
  fill: #0c9efb;

  &:hover {
    background-color: #0c9efb;
    fill: #fff;
  }
}

.reviews__prev {
  transform: rotate(-180deg);
  margin-right: 20px;
}

.reviews__next-svg {
  width: 12px;
  height: 12px;
}

.reviews__slide {
  padding: 30px 15px;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: rgba(12, 158, 251, 0.08);

  @media ($tablet) {
    padding: 30px;
  }

  @media ($desktop) {
    width: 370px;
  }
}

.reviews__header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.reviews__img {
  width: 36px;
  height: 36px;
  border-radius: 100%;
  margin-right: 8px;
}

.reviews__name {
  font-family: $fontBold;
  font-size: 14px;
  margin: 0;

  @media ($desktop) {
    font-size: 16px;
  }
}

.reviews__social {
  margin-left: auto;
}

.reviews__social-vk {
  width: 24px;
  height: 14px;
  fill: #0c9efb;
  opacity: .3;
}

.reviews__text {
  font-size: 12px;
  line-height: 18px;
  margin: 0 0 20px;

  @media ($tablet) {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 25px;
  }

  @media ($desktop) {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 30px;
  }
}

.reviews__link {
  font-family: $fontBold;
  font-size: 14px;
  opacity: .4;
  text-decoration: none;

  @media ($desktop) {
    font-size: 16px;
  }
}