@import "../variables";

.trust {
  padding: 50px 0 60px;
  background: #f0f4f8 url('../img/bg-trust.png') 100% 100% no-repeat;

  @media ($tablet) {
    background: #f0f4f8;
    padding: 70px 0 118px;
    position: relative;

    &::after {
      content: url('../img/bg-trust-tablet.png');
      position: absolute;
      bottom: -220px;
      right: 0;
    }
  }

  @media ($desktop) {
    padding: 120px 0 130px;

    &::after {
      display: none;
    }
  }
}

.trust__wrapper {
  @media ($tablet) {
    background-color: #fff;
    display: flex;
    justify-content: space-between;
  }

  @media ($desktop) {
    position: relative;

    &::after {
      content: url('../img/bg-trust-desktop.png');
      position: absolute;
      bottom: -320px;
      right: -120px;
      z-index: 15;
    }
  }
}

.trust__why {
  @media ($tablet) {
    flex-basis: 33%;
    padding: 40px 20px;
    box-sizing: border-box;
    box-shadow: 0 2px 30px 0 rgba(226, 229, 235, 0.5);
  }

  @media ($desktop) {
    flex-basis: 42%;
    padding: 65px 50px 120px;
  }
}

.trust__why-title {
  font-family: $fontSemiBold;
  font-size: 24px;
  line-height: 33px;
  width: 200px;
  margin: 0 0 30px;

  @media ($tablet) {
    font-size: 28px;
    line-height: 37px;
  }

  @media ($desktop) {
    font-size: 32px;
    line-height: 40px;
  }
}

.trust__why-text {
  line-height: 21px;
  margin: 0 0 30px;

  @media ($tablet) {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 40px;
  }

  @media ($desktop) {
    font-size: 16px;
    line-height: 26px;
  }
}

.trust__button {
  height: 60px;
}

.trust__since {
  margin-top: 50px;

  @media ($tablet) {
    margin-top: 0;
    flex-basis: 66%;
    padding: 53px 30px;
    position: relative;

    &::after {
      content: '1996';
      position: absolute;
      left: 0;
      bottom: -11px;
      font-family: $fontBold;
      font-size: 150px;
      line-height: 125px;
      letter-spacing: -5px;
      opacity: .5;
      color: #f0f4f8;
    }
  }

  @media ($desktop) {
    flex-basis: 58%;
    padding: 60px 50px;

    &::after {
      left: 50px;
      font-size: 200px;
      line-height: 157px;
    }
  }
}

.trust__since-title {
  font-family: $fontSemiBold;
  font-size: 28px;
  line-height: 38px;
  width: 180px;
  margin: 0 0 30px;

  @media ($tablet) {
    font-size: 36px;
    line-height: 49px;
    width: 240px;
  }

  @media ($desktop) {
    font-size: 48px;
    line-height: 58px;
    width: 320px;
  }
}

.trust__since-text {
  font-family: $fontMedium;
  font-size: 18px;
  line-height: 25px;
  margin: 0;

  @media ($tablet) {
    font-size: 16px;
  }

  @media ($desktop) {
    font-size: 20px;
    line-height: 30px;
    width: 570px;
  }
}