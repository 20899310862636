@import "../variables";

.main-slider {
  color: #fff;
  padding-top: 40px;

  @media ($tablet) {
    padding-top: 0;
    margin-left: 120px;
  }

  @media ($desktop) {
    margin-left: 200px;
  }
}

.main-slider__title {
  font-family: $fontBold;
  font-size: 24px;
  line-height: 34px;
  margin: 0 0 30px;
  width: 250px;

  @media ($tablet) {
    font-size: 36px;
    line-height: 48px;
    width: 360px;
  }

  @media ($desktop) {
    font-size: 50px;
    line-height: 70px;
    width: 520px;
  }
}

.main-slider__text {
  font-size: 12px;
  line-height: 20px;
  margin: 0 0 30px;

  @media ($tablet) {
    font-size: 14px;
    line-height: 22px;
    width: 360px;
  }

  @media ($desktop) {
    font-size: 16px;
    line-height: 24px;
    width: 470px;
  }
}