@import "../variables";

.review {
  background-color: #f3faff;
  margin: 30px -15px;
  padding: 30px 15px 30px 70px;
  position: relative;

  &::before {
    content: url('../img/svg/icon-review.svg');
    position: absolute;
    left: 15px;
    top: 35px;

    @media ($tablet) {
      left: 30px;
    }
  }

  @media ($tablet) {
    margin: 30px 0;
    padding-left: 90px;
  }

  @media ($desktop) {
    box-sizing: border-box;
    width: 830px;
    padding-right: 30px;
  }
}

.review__text {
  font-family: $fontMedium;
  font-size: 14px;
  line-height: 20px;
  margin: 0 0 20px;

  @media ($tablet) {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 30px;
  }

  @media ($desktop) {
    font-size: 18px;
  }
}

.review__author {
  font-family: $fontBold;
  font-size: 14px;
  line-height: 20px;
  margin: 0;

  @media ($desktop) {
    font-size: 16px;
  }
}

.review__company {
  font-size: 12px;
  line-height: 18px;
  margin: 0;
  opacity: .4;

  @media ($desktop) {
    font-size: 14px;
  }
}