@import "../variables";

.popup {
  display: none;

  &--show {
    display: block;
  }

  &--callback {
    padding: 0 15px;
    overflow-y: auto;
  }
}