@import "../variables";

.services {
  padding: 50px 0 60px;

  @media ($tablet) {
    padding: 70px 0;
  }

  @media ($desktop) {
    padding: 96px 0 170px;
  }
}

.services__header {
  @media ($tablet) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media ($desktop) {
    justify-content: flex-start;
    .title-default {
      width: 770px;
    }
  }
}

.services__callback {
  margin-top: 30px;

  @media ($tablet) {
    margin-top: 0;
  }

  @media ($desktop) {
    margin-left: 30px;
  }
}

.services__callback-title {
  margin: 0 0 15px;
  font-family: $fontBold;
  font-size: 16px;
  line-height: 24px;
  width: 200px;

  @media ($tablet) {
    font-size: 18px;
    line-height: 25px;
  }

  @media ($desktop) {
    font-size: 28px;
    line-height: 38px;
    width: 370px;
    margin-bottom: 30px;
  }
}

.services__callback-button {
  height: 60px;
  width: 190px;

  @media ($tablet) {
    width: 206px;
  }
}

.services__list {
  margin: 60px 0 0;
  padding: 0;
  list-style-type: none;

  @media ($tablet) {
    margin-top: 70px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.services__item {
  @media ($tablet) {
    width: 324px;
    box-sizing: border-box;
    padding: 30px;
    border-left: 1px solid #f2f2f2;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    transition: background-color .2s;

    &:hover {
      background-color: rgba(12, 158, 251, 0.1);
    }
  }

  @media ($desktop) {
    width: 270px;
  }

  &:not(:first-child) {
    margin-top: 90px;

    @media ($tablet) {
      margin-top: 0;
    }
  }
}

.services__icon {
  margin-bottom: 20px;
}

.services__title {
  font-family: $fontSemiBold;
  font-size: 16px;
  margin: 0 0 20px;

  @media ($tablet) {
    font-size: 18px;
  }
}

.services__text {
  font-size: 12px;
  line-height: 18px;
  margin: 0 0 30px;

  @media ($tablet) {
    font-size: 14px;
    line-height: 22px;
  }
}

.services__link {
  display: flex;
  align-items: center;
  text-decoration: none;

  @media ($tablet) {
    margin-top: auto;
    font-size: 14px;
  }

  &:hover {
    text-decoration: underline;

    .services__svg {
      margin-left: 12px;
    }
  }
}

.services__svg {
  width: 12px;
  height: 12px;
  margin-left: 9px;
  transition: margin-left .2s;
}