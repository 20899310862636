@import "variables";

@font-face {
  font-family: 'gilroybold';
  src: url('../font/gilroy-bold-webfont.woff2') format('woff2'),
  url('../font/gilroy-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'gilroyextrabold_italic';
  src: url('../font/gilroy-extrabolditalic-webfont.woff2') format('woff2'),
  url('../font/gilroy-extrabolditalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'gilroymedium';
  src: url('../font/gilroy-medium-webfont.woff2') format('woff2'),
  url('../font/gilroy-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'gilroyregular';
  src: url('../font/gilroy-regular-webfont.woff2') format('woff2'),
  url('../font/gilroy-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'gilroysemibold';
  src: url('../font/gilroy-semibold-webfont.woff2') format('woff2'),
  url('../font/gilroy-semibold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}