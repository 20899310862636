@import "../variables";

.img {
  margin-bottom: 15px;

  @media ($tablet) {
    &--right {
      width: auto;
      float: right;
      margin-left: 24px;
      margin-bottom: 14px;
    }

    &--left {
      width: auto;
      float: left;
      margin-right: 24px;
      margin-bottom: 14px;
    }

    &--full {
      display: block;
      width: 100%;
    }
  }
}