@import "../variables";

.col {
  margin: 30px 0;

  @media ($tablet) {
    &--2 {
      column-count: 2;
      column-gap: 24px;
    }

    &--3 {
      column-count: 3;
      column-gap: 24px;
    }
  }
}