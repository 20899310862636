@import "../variables";

.video {
  margin-bottom: 40px;

  iframe {
    border: 0;
    width: 100%;

    @media ($tablet) {
      height: 350px;
    }

    @media ($desktop) {
      height: 670px;
    }
  }
}