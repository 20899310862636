@import "../variables";

.additional {
  padding: 40px 0 50px;

  @media ($tablet) {
    padding: 70px 0;

    .title-h2 {
      font-size: 36px;
      line-height: 48px;
      width: 324px;
      margin-bottom: 70px;
    }
  }

  @media ($desktop) {
    padding: 70px 0 100px;

    .title-h2 {
      font-size: 50px;
      line-height: 70px;
      width: 520px;
      margin-bottom: 63px;
    }
  }
}

.additional__list {
  margin: 0;
  padding: 0;
  list-style-type: none;

  @media ($tablet) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.additional__item {
  @media ($tablet) {
    flex-basis: 48%;
  }

  &:not(:first-child) {
    margin-top: 30px;

    @media ($tablet) {
      margin-top: 0;
    }
  }
}

.additional__img {
  width: 100%;
  margin-bottom: 20px;

  @media ($desktop) {
    margin-bottom: 30px;
  }
}

.additional__title {
  font-family: $fontSemiBold;
  font-size: 16px;
  line-height: 23px;
  margin: 0 0 20px;

  @media ($tablet) {
    margin-bottom: 12px;
  }

  @media ($desktop) {
    font-size: 18px;
    margin-bottom: 23px;
  }
}

.additional__text {
  font-size: 12px;
  line-height: 18px;
  margin: 0 0 20px;

  @media ($tablet) {
    margin-bottom: 30px;
  }

  @media ($desktop) {
    font-size: 14px;
    line-height: 22px;
  }
}

.additional__btn {
  height: 60px;
}