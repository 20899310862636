@import "../variables";

.faq {
  border-top: 1px solid rgba(0,0,0,.1);
  margin: 0 -24px;

  @media ($tablet) {
    margin: 0;
  }

  @media ($desktop) {
    width: 780px;
  }
}

.faq__item {
  padding: 0 24px;
  border-bottom: 1px solid rgba(0,0,0,.1);
}

.faq__title {
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  font-size: 16px;
  font-family: $fontSemiBold;

  @media ($desktop) {
    font-size: 18px;
  }

  &::after {
    content: '+';
    font-size: 20px;
    color: #0c9efb;
  }

  &--active {
    & + .faq__content {
      display: block;
    }

    &::after {
      content: '—';
      font-size: 13px;
      color: #0c9efb;
    }
  }
}

.faq__content {
  display: none;
  margin: 0 0 10px;

  @media ($tablet) {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 25px;
  }

  @media ($desktop) {
    font-size: 16px;
    line-height: 26px;
  }
}