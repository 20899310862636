@import "../variables";

.form {
  position: relative;

  &--bg {
    .form__input,
    .form__textarea {
      color: #fff;
    }

    .form__label {
      opacity: 1;
    }
  }

  &--popup {
    background-color: #fff;
    border-radius: 8px;
    color: #000;
    margin: 0 -15px;
    padding: 30px 15px 15px;

    @media ($tablet) {
      box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.2);
      width: 324px;
      margin: 0;
      box-sizing: border-box;
      padding: 20px;

      .form__item {
        &--agree {
          margin-bottom: 0;
        }
      }

      .form__title {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 5px;
      }

      .form__check {
        font-size: 12px;
        line-height: 18px;
      }
    }

    @media ($desktop) {
      width: 430px;
      padding: 30px;

      .form__title {
        font-size: 22px;
        line-height: 32px;
      }

      .form__text {
        font-size: 16px;
      }

      .form__check {
        font-size: 14px;
        justify-content: center;
      }
    }
  }

  &--subscribe {
    @media ($tablet) {
      .form__item {
        &--agree {
          margin-bottom: 0;
        }
      }

      .form__check {
        font-size: 12px;
        line-height: 18px;
      }
    }

    @media ($desktop) {
      width: 370px;
    }
  }

  &--stock {
    @media ($tablet) {
      .form__item {
        &--agree {
          margin-bottom: 0;
        }
      }

      .form__check {
        font-size: 12px;
        line-height: 18px;
      }
    }

    @media ($desktop) {
      width: 370px;
    }
  }
}

.form__content {
  @media ($tablet) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  @media ($desktop) {
    align-items: flex-end;
  }
}

.form__item {
  position: relative;

  @media ($tablet) {
    flex-basis: 48%;
    margin-bottom: 30px;
  }

  @media ($desktop) {
    flex-basis: 32%;
  }

  &:not(:first-child) {
    margin-top: 20px;

    @media ($tablet) {
      margin-top: 0;
    }
  }

  &--agree {
    padding-left: 9px;

    @media ($tablet) {
      padding-left: 0;
    }
  }

  &--button {
    @media ($tablet) {
      order: 1;
    }

    @media ($desktop) {
      order: 0;
    }
  }

  &--last {
    @media ($desktop) {
      order: 1;
    }
  }

  &--right {
    @media ($desktop) {
      margin-left: auto;
      align-self: center;
    }
  }

  &--email {
    @media ($tablet) {
      flex-basis: 100%;
    }

    @media ($desktop) {
      flex-basis: 32%;
    }
  }

  &--text {
    @media ($tablet) {
      flex-basis: 100%;
    }
  }
}

.form__title {
  font-family: $fontBold;
  font-size: 16px;
  line-height: 24px;
  margin: 0 0 3px;

  @media ($tablet) {
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 30px;
  }

  &--upper {
    font-size: 20px;
    line-height: 27px;
    text-transform: uppercase;

    @media ($tablet) {
      font-size: 28px;
      margin-bottom: 9px;
    }
  }
}

.form__text {
  font-size: 16px;
  line-height: 23px;
  font-family: $fontMedium;
  opacity: .75;
  margin: 0 0 20px;

  @media ($tablet) {
    font-size: 20px;
  }

  &--smaller {
    font-size: 12px;
    line-height: 18px;
    font-family: $fontRegular;
  }
}

.form__label {
  opacity: .3;

  @media ($tablet) {
    font-size: 16px;
  }
}

.form__input {
  width: 100%;
  border-bottom: 1px solid #e6e6e6;
  height: 50px;
  margin-top: 5px;
  font-size: 16px;
  padding: 0 15px 0 42px;
  font-family: $fontBold;

  @media ($tablet) {
    height: 60px;
    font-size: 18px;
  }
}

.form__textarea {
  width: 100%;
  border-bottom: 1px solid #e6e6e6;
  height: 100px;
  margin-top: 5px;
  font-size: 16px;
  padding: 15px 0;
  font-family: $fontBold;
}

.form__item-name {
  width: 20px;
  height: 19px;
  position: absolute;
  left: 0;
  top: 50px;

  @media ($tablet) {
    top: 56px;
  }
}

.form__item-phone {
  width: 20px;
  height: 20px;
  position: absolute;
  left: 0;
  top: 50px;

  @media ($tablet) {
    top: 56px;
  }
}

.form__item-email {
  width: 20px;
  height: 15px;
  position: absolute;
  left: 0;
  top: 54px;

  @media ($tablet) {
    top: 60px;
  }
}

.form__button {
  width: 100%;
  height: 67px;
  border-radius: 38.3px;
  border-bottom: 3px solid #178fdb;
  font-size: 16px;
  box-shadow: 0 10px 30px -5px rgba(28, 150, 227, 0.5);
}

.form__check {
  font-size: 12px;
  line-height: 32px;

  @media ($tablet) {
    font-size: 14px;
    justify-content: center;
  }

  @media ($desktop) {
    justify-content: flex-start;
  }
}

.form__link {
  margin-left: 3px;
  color: #0c9efb;
}

.form__close {
  position: absolute;
  top: 15px;
  right: 15px;
}

.form__close-svg {
  width: 14px;
  height: 15px;
}

.form__stock-title {
  text-align: center;
  font-family: $fontBold;
  font-size: 44px;
  line-height: 48px;
  color: #0c9efb;
  margin: 0 0 10px;
  text-transform: uppercase;
}

.form__stock-text {
  font-family: $fontBold;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  margin: 0 0 10px;
}

.form__timer {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.form__timer-item {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;

  &:not(:first-child) {
    margin-left: 30px;

    &::before {
      content: ':';
      font-family: $fontBold;
      color: #0c9efb;
      font-size: 50px;
      position: absolute;
      top: 0;
      left: -20px;
    }
  }
}

.form__timer-count {
  font-family: $fontBold;
  color: #0c9efb;
  font-size: 50px;
}

.form__timer-text {
  font-size: 14px;
  text-transform: uppercase;
  font-family: $fontBold;
  opacity: .4;
}