@import "../variables";

.textarea {
  padding: 0;
  margin: 0;
  background: transparent;
  box-sizing: border-box;
  border: none;
  font-family: $fontRegular;
  resize: vertical;
}