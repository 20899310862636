@import "../variables";

.form-wrapper {
  padding: 30px 0;

  @media ($tablet) {
    padding: 75px 0 35px;
  }

  &--bg {
    background: #000 url('../img/bg-question.jpg') 50% 0 no-repeat;
    background-size: cover;
    color: #fff;

    @media ($tablet) {
      background-image: url('../img/bg-question-tablet.jpg');
    }

    @media ($tablet) {
      background-image: url('../img/bg-question-desktop.jpg');
    }
  }

  &--black {
    background-color: rgba(0,0,0,.9);
  }
}

.form-half {
  @media ($tablet) {
    display: flex;
    justify-content: space-between;
  }

  @media ($desktop) {
    .form-wrapper {
      flex-basis: 50%;
    }

    .container {
      width: auto;
      padding: 30px;
    }
  }
}