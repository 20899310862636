@import "../variables";

.footer {
  background-color: #272727;
  color: #fff;
  padding: 40px 0 30px;

  @media ($tablet) {
    padding-top: 65px;
  }

  @media ($desktop) {
    padding: 65px 0 45px;
  }
}

.footer__wrapper {
  @media ($tablet) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.footer__item {
  text-align: center;
  margin-bottom: 30px;

  @media ($tablet) {
    flex-basis: 50%;
    text-align: left;
    margin-bottom: 25px;

    &--nav {
      order: 1;
    }

    &--clients {
      order: 3;
    }

    &--contacts {
      order: 2;
    }

    &--social {
      order: 4;
    }
  }

  @media ($desktop) {
    flex-basis: 25%;
    margin-bottom: 64px;

    &--clients {
      order: 2;
    }

    &--contacts {
      order: 3;
    }
  }
}

.footer__title {
  margin: 0 0 10px;
  font-family: $fontSemiBold;
  font-size: 16px;

  @media ($tablet) {
    font-size: 18px;
    margin-bottom: 18px;
  }
}

.footer__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.footer__list-item {
  line-height: 34px;

  @media ($tablet) {
    line-height: 24px;
  }
}

.footer__list-link {
  font-size: 14px;
  text-decoration: none;
  color: #fff;

  &:hover {
    text-decoration: underline;
  }
}

.footer__text {
  font-size: 16px;

  @media ($tablet) {
    font-size: 18px;
    line-height: 28px;
    margin: 0;
  }
}

.footer__email {
  margin: 20px 0 0;
  padding: 0;
  list-style-type: none;

  @media ($tablet) {
    margin-top: 28px;
  }
}

.footer__email-item {
  line-height: 28px;
}

.footer__email-link {
  font-size: 16px;
  text-decoration: none;
  color: #fff;

  @media ($tablet) {
    font-size: 18px;
  }

  &:hover {
    text-decoration: underline;
  }
}

.footer__phone {
  font-family: $fontBold;
  font-size: 22px;
  text-decoration: none;
  color: #fff;

  &:hover {
    text-decoration: underline;
  }
}

.footer__fax {
  font-family: $fontMedium;
  font-size: 18px;
}

.footer__social {
  margin: 30px 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ($tablet) {
    justify-content: flex-start;
  }
}

.footer__social-item {
  @media ($tablet) {
    margin-right: 20px;
  }
}

.footer__social-link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 100%;

  &--vk {
    background-color: #4a76a8;
  }

  &--fb {
    background-color: #3b5998;
  }

  &--tw {
    background-color: #1da1f2;
  }

  &--inst {
    background: url('../img/bg-inst-icon.png') 50% 50% no-repeat;
  }
}

.footer__social-vk {
  width: 14px;
  height: 8px;
  fill: #fff;
}

.footer__social-fb {
  width: 7px;
  height: 14px;
  fill: #fff;
}

.footer__social-inst {
  width: 14px;
  height: 14px;
  fill: #fff;
}

.footer__social-tw {
  width: 14px;
  height: 12px;
  fill: #fff;
}

.footer__copy-wrapper {
  margin: 0 -15px;
  padding: 30px 15px 0;
  border-top: 2px solid rgba(255,255,255,.3);
  text-align: center;

  @media ($tablet) {
    margin: 0;
    padding: 20px 0 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    text-align: left;
  }

  @media ($desktop) {
    padding-top: 40px;
    justify-content: flex-start;
  }
}

.footer__copy-text {
  margin: 0 0 10px;
  line-height: 24px;

  @media ($tablet) {
    margin: 0;
    width: 236px;
    font-size: 14px;
    order: 1;
  }

  @media ($desktop) {
    width: auto;
  }
}

.footer__copy {
  margin: 0 0 10px;
  line-height: 24px;
  opacity: .3;

  @media ($tablet) {
    margin: 10px 0 0;
    flex-basis: 100%;
    order: 3;
    font-size: 14px;
  }

  @media ($desktop) {
    order: 2;
    flex-basis: auto;
    margin: 0 0 0 20px;
  }
}

.footer__dev {
  margin: 0;
  line-height: 24px;

  @media ($tablet) {
    order: 2;
    font-size: 14px;
  }

  @media ($desktop) {
    order: 3;
    margin-left: auto;
  }
}

.footer__dev-link {
  font-family: $fontBold;
  color: #fff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}