@import "../variables";

.contacts {
  padding: 115px 0 50px;
  background: #4e6379 url('../img/bg-contacts.jpg') 50% 0 no-repeat;
  background-size: cover;
  position: relative;
  color: #fff;

  @media ($tablet) {
    padding: 240px 0 70px;
    background-image: url('../img/bg-contacts-tablet.jpg');
  }

  @media ($desktop) {
    padding: 183px 0 70px;
    background-image: url('../img/bg-contacts-desktop.jpg');
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #4e6379;
    opacity: .55;
    z-index: 1;
  }

  .container {
    position: relative;
    z-index: 2;
  }

  .title-default {
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 15px;

    @media ($tablet) {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 0;
    }
  }

  .title-h2 {
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 20px;

    @media ($tablet) {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 30px;
    }
  }
}

.contacts__top-wrapper {
  @media ($desktop) {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
  }
}

.contacts__top {
  @media ($desktop) {
    width: 470px;
  }
}

.contacts__phone {
  text-decoration: none;
  color: #fff;
  font-size: 28px;
  line-height: 38px;
  font-family: $fontBold;

  @media ($tablet) {
    font-size: 50px;
    line-height: 70px;
  }

  &:hover {
    text-decoration: underline;
  }
}

.contacts__text {
  font-family: $fontMedium;
  font-size: 12px;
  line-height: 24px;
  opacity: .8;
  margin: 0 0 15px;

  @media ($tablet) {
    font-size: 16px;
    margin-bottom: 38px;
  }
}

.contacts__wrapper {
  margin-bottom: 20px;

  @media ($tablet) {
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
  }

  @media ($desktop) {
    margin-bottom: 0;
  }
}

.contacts__address {
  font-family: $fontMedium;
  margin-bottom: 20px;

  @media ($tablet) {
    flex-basis: 50%;
    margin-bottom: 0;
  }

  @media ($desktop) {
    flex-basis: 61%;
  }

  p {
    font-size: 20px;
    line-height: 28px;
    margin: 0;

    @media ($tablet) {
      font-size: 32px;
      line-height: 43px;
    }
  }
}

.contacts__pdf {
  margin-top: 10px;
  display: flex;
  align-items: center;
  color: #fff;

  @media ($tablet) {
    font-size: 18px;
    line-height: 26px;
  }
}

.contacts__pdf-svg {
  width: 18px;
  height: 24px;
  margin-right: 10px;
}

.contacts__mode-wrapper {
  display: flex;
  justify-content: space-between;

  @media ($tablet) {
    display: block;
    flex-basis: 50%;
  }

  @media ($desktop) {
    flex-basis: 39%;
  }
}

.contacts__mode {
  flex-basis: 45%;
  font-size: 14px;
  line-height: 22px;

  @media ($tablet) {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 20px;
  }

  p {
    margin: 0;
  }
}

.contacts__email {
  flex-basis: 45%;

  @media ($tablet) {
    display: flex;
    flex-direction: column;
  }
}

.contacts__email-link {
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  line-height: 22px;

  @media ($tablet) {
    font-size: 18px;
    line-height: 26px;
  }

  &:hover {
    text-decoration: underline;
  }
}

.contacts__map-wrapper {
  margin: 0 -15px 30px;

  @media ($tablet) {
    margin: 0 0 40px;
  }

  @media ($desktop) {
    width: 570px;
    margin: 0;
  }
}

.contacts__map {
  width: 100%;
  height: 180px;

  @media ($tablet) {
    height: 325px;
  }
}

.contacts__form-wrapper {
  margin-top: 20px;

  @media ($tablet) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .contacts__form-item {
      flex-basis: 48%;
      margin-bottom: 20px;

      &--email {
        flex-basis: 100%;
      }

      &:not(:first-child) {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &--agree {
      align-items: center;
      flex-direction: row-reverse;
      margin-top: 30px;
    }
  }

  @media ($desktop) {
    .contacts__form-item {
      flex-basis: 31%;
      margin-bottom: 0;

      &--email {
        flex-basis: 31%;
      }
    }

    &--agree {
      justify-content: flex-start;
    }
  }
}

.contacts__form-item {
  position: relative;

  &:not(:first-child) {
    margin-top: 20px;

    @media ($tablet) {
      margin-top: 25px;
    }
  }

  &--agree {
    padding-left: 9px;

    @media ($tablet) {
      padding-left: 0;
    }
  }
}

.contacts__label {
  @media ($tablet) {
    font-size: 16px;
  }
}

.contacts__input {
  width: 100%;
  border-bottom: 1px solid #e6e6e6;
  height: 50px;
  margin-top: 5px;
  font-size: 16px;
  padding: 0 15px 0 42px;
  font-family: $fontBold;
  color: #fff;

  @media ($tablet) {
    height: 60px;
    font-size: 18px;
  }
}

.contacts__name-svg {
  width: 20px;
  height: 19px;
  position: absolute;
  left: 0;
  top: 50px;
  opacity: .3;

  @media ($tablet) {
    top: 56px;
  }
}

.contacts__phone-svg {
  width: 20px;
  height: 20px;
  position: absolute;
  left: 0;
  top: 50px;
  opacity: .3;

  @media ($tablet) {
    top: 56px;
  }
}

.contacts__email-svg {
  width: 20px;
  height: 15px;
  position: absolute;
  left: 0;
  top: 54px;

  @media ($tablet) {
    top: 56px;
  }
}

.contacts__button {
  width: 100%;
  height: 67px;
  border-radius: 38.3px;
  border-bottom: 3px solid #178fdb;
  font-size: 16px;
  box-shadow: 0 10px 30px -5px rgba(28, 150, 227, 0.5);
}

.contacts__check {
  font-size: 12px;
  line-height: 32px;

  @media ($tablet) {
    font-size: 14px;
    justify-content: center;
  }
}

.contacts__link {
  margin-left: 3px;
  color: #0c9efb;
}

.contacts__textarea {
  width: 100%;
  border-bottom: 1px solid #e6e6e6;
  height: 100px;
  margin-top: 5px;
  font-size: 16px;
  padding: 15px 0;
  font-family: $fontBold;
  color: #fff;
}