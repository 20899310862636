@import "../variables";

.accreditation {
  padding: 40px 0 50px;

  @media ($tablet) {
    padding: 70px 0;
    position: relative;
  }

  @media ($desktop) {
    padding: 70px 0 97px;
  }
}

.accreditation__header {
  @media ($tablet) {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
}

.accreditation__title {
  width: 220px;

  @media ($tablet) {
    font-size: 36px;
    line-height: 48px;
    width: 300px;
    margin: 0;
  }

  @media ($desktop) {
    font-size: 50px;
    line-height: 70px;
    width: 500px;
  }
}

.accreditation__controls {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;

  @media ($tablet) {
    margin-bottom: 10px;
  }
}

.accreditation__next,
.accreditation__prev {
  box-sizing: border-box;
  position: static;
  background-image: none;
  width: 55px;
  height: 55px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #0c9efb;
  background-color: #fff;
  margin: 0;
  transition: background-color .2s;
  fill: #0c9efb;

  &:hover {
    background-color: #0c9efb;
    fill: #fff;
  }
}

.accreditation__prev {
  transform: rotate(-180deg);
  margin-right: 20px;
}

.accreditation__next-svg {
  width: 12px;
  height: 12px;
}

.accreditation__slider {
  margin: 40px -15px 0;
  padding: 30px 0 30px 15px;
  box-sizing: border-box;
}

.accreditation__slide {
  border-radius: 2px;
  box-shadow: 0 5px 5px 5px rgba(226, 229, 235, 1);
  padding: 35px 30px 35px;
  text-align: center;
  box-sizing: border-box;
  background-color: #fff;
  width: 270px;
  min-height: 270px;
}

.accreditation__text {
  font-size: 12px;
  line-height: 18px;
  text-align: left;
  opacity: .3;
  margin: 30px 0 0;
}