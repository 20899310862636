@import "../variables";

.question {
  padding: 50px 0;
  background: #584a49 url('../img/bg-question.jpg') 0 0 no-repeat;
  background-size: cover;
  color: #fff;

  @media ($tablet) {
    padding: 70px 0;
    background-image: url('../img/bg-question-tablet.jpg');
  }

  @media ($desktop) {
    padding: 108px 0;
    background-image: url('../img/bg-question-desktop.jpg');
  }
}

.question__wrapper {
  @media ($desktop) {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.question__header {
  @media ($desktop) {
    position: relative;
    padding-top: 70px;

    &::after {
      content: url('../img/question-arrow.png');
      position: absolute;
      right: 0;
      bottom: -90px;
    }
  }
}

.question__title {
  font-size: 26px;
  line-height: 35px;
  font-family: $fontSemiBold;
  margin-bottom: 20px;

  @media ($tablet) {
    font-size: 36px;
    line-height: 48px;
    font-family: $fontBold;
    width: 440px;
    margin: 0 auto 20px;
    text-align: center;
  }

  @media ($desktop) {
    font-size: 48px;
    line-height: 58px;
    text-align: left;
    width: 570px;
    margin-bottom: 30px;
  }

  b {
    font-family: $fontExtraBoldItalic;

    @media ($tablet) {
      font-family: $fontBold;
    }

    @media ($desktop) {
      font-family: $fontExtraBoldItalic;
    }
  }
}

.question__text {
  font-size: 16px;
  line-height: 23px;
  font-family: $fontMedium;
  margin: 0 0 50px;

  @media ($tablet) {
    line-height: 25px;
    width: 440px;
    margin: 0 auto 40px;
    text-align: center;
  }

  @media ($desktop) {
    font-size: 20px;
    line-height: 30px;
    text-align: left;
    width: 570px;
    margin: 0;
  }
}