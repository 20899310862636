@import "../variables";

.user-actions {
  margin-top: 20px;
  border-top: 1px solid rgba(68, 69, 78, 0.09);
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}

.user-actions__date {
  margin: 0;
  font-size: 12px;
  color: #d6d5d6;

  @media ($desktop) {
    font-size: 18px;
  }
}

.user-actions__tools {
  display: flex;
  align-content: center;
}

.user-actions__comments {
  font-family: $fontBold;
  font-size: 12px;
  line-height: 16px;
  text-decoration: none;
  margin-right: 20px;
  display: flex;
  align-items: center;

  @media ($desktop) {
    font-size: 18px;
  }
}

.user-actions__comments-svg {
  width: 14px;
  height: 14px;
  margin-left: 5px;
}

.user-actions__view {
  font-family: $fontBold;
  font-size: 12px;
  line-height: 16px;
  text-decoration: none;
  display: flex;
  align-items: center;
  margin-right: 20px;

  @media ($desktop) {
    font-size: 18px;
  }
}

.user-actions__view-svg {
  width: 16px;
  height: 16px;
  margin-left: 5px;
}

.user-actions__favorite {
  font-family: $fontBold;
  font-size: 12px;
  line-height: 16px;
  text-decoration: none;
  display: flex;
  align-items: center;

  @media ($desktop) {
    font-size: 18px;
  }
}

.user-actions__favorite-svg {
  width: 14px;
  height: 14px;
  margin-left: 5px;
}

.user-actions__social {
  margin-top: 20px;
}

.user-actions__icons {
  display: none;
}