@import "../variables";

.text {
  padding: 20px 0 30px;
}

.text__content {
  & > p {
    font-size: 12px;
    line-height: 18px;

    @media ($tablet) {
      font-size: 14px;
      line-height: 22px;
    }

    @media ($desktop) {
      font-size: 16px;
      line-height: 26px;
    }
  }

  & > ul {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
      position: relative;
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 10px;
      padding-left: 15px;

      &::before {
        content: '';
        position: absolute;
        top: 8px;
        left: 0;
        width: 4px;
        height: 4px;
        background-color: #0c9efb;
        border-radius: 100%;
      }

      @media ($desktop) {
        font-size: 16px;
      }
    }
  }

  & > ol {
    margin: 0;
    padding: 0;
    list-style-type: none;
    counter-reset: list;

    li {
      position: relative;
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 10px;
      padding-left: 15px;

      &::before {
        counter-increment: list;
        content: counter(list) ".";
        position: absolute;
        top: 0;
        left: 0;
        font-family: $fontBold;
        font-size: 12px;
      }

      @media ($desktop) {
        font-size: 16px;
      }
    }
  }

  table {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 30px;

    @media ($desktop) {
      width: 780px;
    }

    tr {
      border-bottom: 1px solid rgba(0,0,0,.1);
    }

    th {
      font-family: $fontBold;
      font-size: 12px;
      text-align: left;
      height: 45px;

      @media ($tablet) {
        height: 50px;
        font-size: 16px;
      }
    }

    td {
      font-size: 12px;
      height: 45px;

      @media ($tablet) {
        height: 50px;
        font-size: 16px;
      }
    }
  }
}